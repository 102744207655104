import cookies from "next-cookies";
import { putUser } from "pages/api";

const LanguageHook = (router, lang, successAlert) => {
  const token = cookies("token")?.token ?? "";
  const query = router?.query;
  const currentLang = process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;

  if (!lang) {
    // TH ko co lang thi mac dinh lay tieng viet
    document.cookie = `lang=${currentLang}; path=/;`;
    // return router.push({
    //   pathname: router?.pathname,
    //   query: query,
    // });
  } else {
    if (token?.length > 0 && lang) {
      //TH member da login
      //goi api luu language
      putUser({ values: { language: lang } })
        .then((req) => {
          document.cookie = `lang=${req?.data?.data?.language}; path=/;`;
          successAlert && successAlert();
          // return router.push({
          //   pathname: router?.pathname,
          //   query: query,
          // });
        })
        .catch((err) => {
          console.log("change language err", err.response);
        });
    } else {
      //TH member chua login
      document.cookie = `lang=${lang}; path=/;`;
      return router.push({
        pathname: router?.pathname,
        query: { ...query, update: true },
      });
    }
  }
};

export default LanguageHook;
