const TYPE_PERSONAL = {
    id: 1,
    key: "1",
    vi: "Cá nhân",
    en: "Personal",
    color: "#65FFB9",
}

const TYPE_ORGANIZATION = {
    id: 2,
    key: "2",
    vi: "Tổ chức",
    en: "Organization",
    color: "#89d0f2",
}

const TYPE_STRONG_SPONSOR = {
    id: 3,
    key: "3",
    vi: "Mạnh thường quân",
    en: "Sponsor",
    color: "#ffb84f",
}

const type_user = [TYPE_PERSONAL, TYPE_ORGANIZATION, TYPE_STRONG_SPONSOR]

const findTypeUser = (type, field) => {
    let obj = type_user.find(o => o.id * 1 === type * 1);
    return obj
        ? field && obj?.[field]
            ? obj[field]
            : obj
        : undefined;
}

export {
    TYPE_PERSONAL,
    TYPE_ORGANIZATION,
    TYPE_STRONG_SPONSOR,
    type_user,
    findTypeUser
}