import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import * as colors from '@mui/material/colors';

const CustomButton = styled(Button)(({ theme, bgColor, colorLevel = 500, hoverLevel = "A200", ...rest }) => {
  return ({
    color: bgColor === "white"
      ? "#000"
      : theme.palette.getContrastText(colors[bgColor][colorLevel]),
    backgroundColor: bgColor === "white" ? "#fff" : colors[bgColor][colorLevel],
    '&:hover': {
      backgroundColor: bgColor === "white" ? "#fff" : colors[bgColor][hoverLevel],
    },
  })
});

export default CustomButton;