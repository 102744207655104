const { createSvgIcon } = require("@mui/material");

const NotiIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.654 26.553">
    <g
      id="_6646082_alarm_bell_essentials_notification_ui_icon"
      data-name="6646082_alarm_bell_essentials_notification_ui_icon"
      transform="translate(0.767 0.75)"
    >
      <path
        id="Path_10305"
        data-name="Path 10305"
        d="M26.933,22.464l-1.06-1.638a16.265,16.265,0,0,1-2.794-9.154,7.574,7.574,0,0,0-5.4-7.227,2.277,2.277,0,0,0-4.24,0,7.574,7.574,0,0,0-5.4,7.227,16.265,16.265,0,0,1-2.794,9.154l-1.06,1.638A1.108,1.108,0,0,0,5.156,24.2H25.97A1.108,1.108,0,0,0,26.933,22.464Z"
        transform="translate(-4.004 -3)"
        fill="none"
        // stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="Path_10306"
        data-name="Path 10306"
        d="M19.709,25A3.854,3.854,0,0,1,12,25"
        transform="translate(-4.295 -3.801)"
        fill="none"
        // stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </svg>,
  "Noti"
);

export default NotiIcon;
