const { createSvgIcon } = require("@mui/material");

const PostIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.995 26.999">
        <g id="viber_image_2021-11-02_09-57-35-243" transform="translate(-0.5 511.731)">
            <path id="Path_10305" data-name="Path 10305" d="M193.323-511.677a3.655,3.655,0,0,0-.422.185c-.132.069-2.572,2.477-6.369,6.279l-6.158,6.168-.412,2.06a8.218,8.218,0,0,0-.349,2.25.953.953,0,0,0,.576.549,15.492,15.492,0,0,0,2.054-.343c1.04-.206,1.975-.4,2.076-.423.148-.042,1.4-1.273,6.369-6.242,6.955-6.966,6.438-6.385,6.438-7.225-.005-.724-.106-.892-1.257-2.044a5.18,5.18,0,0,0-1.268-1.1A2.083,2.083,0,0,0,193.323-511.677Zm1.421,2.355c.718.718.834.856.808.961a3.278,3.278,0,0,1-.512.6l-.475.475-.94-.94-.935-.935.507-.5a2.6,2.6,0,0,1,.607-.5A5.47,5.47,0,0,1,194.744-509.322Zm-2.239,2.25.924.924-4.853,4.853-4.853,4.853-1.13.222a9.431,9.431,0,0,1-1.162.2,10.042,10.042,0,0,1,.19-1.157l.222-1.125,4.843-4.848c2.662-2.662,4.853-4.843,4.869-4.843S192-507.579,192.5-507.072Z" transform="translate(-169.638 0)" />
            <path id="Path_10306" data-name="Path 10306" d="M2.824-429.884a2.958,2.958,0,0,0-2.2,1.943l-.121.354v18.009l.121.349a2.971,2.971,0,0,0,1.848,1.854l.354.121H20.833l.354-.121a3.008,3.008,0,0,0,1.848-1.838l.121-.312.016-6.19c.011-6.142.011-6.195-.1-6.406a.834.834,0,0,0-.687-.422.834.834,0,0,0-.687.422c-.106.206-.106.3-.106,6.195,0,6.728.026,6.3-.4,6.718s.333.4-9.374.4-8.946.032-9.374-.4-.4.333-.4-9.374-.032-8.946.4-9.374-.011-.4,6.718-.4c5.9,0,5.989,0,6.195-.106a.834.834,0,0,0,.423-.687.834.834,0,0,0-.423-.687c-.206-.106-.29-.106-6.269-.1C5.765-429.926,2.94-429.905,2.824-429.884Z" transform="translate(0 -77.479)" />
        </g>
    </svg>
    , 'Post')

export default PostIcon