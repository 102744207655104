const { createSvgIcon } = require("@mui/material");

const CommunityIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.999 27">
        <g id="community" transform="translate(-0.01)">
            <g id="Group_245" data-name="Group 245" transform="translate(0.01)">
                <g id="Group_244" data-name="Group 244" transform="translate(0)">
                    <path id="Path_4515" data-name="Path 4515" d="M171.574,5.737a2.219,2.219,0,0,0,.984,1.22v.235l-1.744.683a2.41,2.41,0,0,0-1.525,2.338v1.519a.427.427,0,0,0,.426.427H170.8a.426.426,0,1,0,0-.853h-.657V10.213a1.574,1.574,0,0,1,.958-1.534l.02-.007,1.6-.625.848.826a.426.426,0,0,0,.3.121h.007a.426.426,0,0,0,.3-.13l.8-.831,1.593.639.023.008a1.57,1.57,0,0,1,.958,1.534v1.093h-5.391a.426.426,0,0,0,0,.853h5.817a.429.429,0,0,0,.426-.429V10.213a2.41,2.41,0,0,0-1.524-2.337l-1.7-.681V6.957a2.218,2.218,0,0,0,.984-1.221,1.2,1.2,0,0,0,.663-2.126V2.858A2.862,2.862,0,0,0,173.963,0h-.2A2.862,2.862,0,0,0,170.9,2.858v.749a1.2,1.2,0,0,0,.672,2.13Zm4.693-1.447a.418.418,0,0,0,.047.012.347.347,0,0,1-.047.519Zm-1.946,3.185-.47.487-.441-.43V7.244a2.217,2.217,0,0,0,.262.016h.387a2.216,2.216,0,0,0,.262-.016Zm1.093-2.423a1.357,1.357,0,0,1-1.355,1.355h-.387a1.357,1.357,0,0,1-1.356-1.355V3.934a4.385,4.385,0,0,0,2.28-.881,1.908,1.908,0,0,0,.817.783V5.052Zm-3.661-2.194A2.008,2.008,0,0,1,173.76.853h.2a2.008,2.008,0,0,1,2.006,2.006v.269l-.022-.006-.011,0c-.589-.134-.782-.921-.784-.927a.426.426,0,0,0-.761-.156,3.44,3.44,0,0,1-2.5,1.072h0l-.044,0-.011,0-.031,0-.016,0-.025.006-.01,0V2.858Zm-.36,1.453a.42.42,0,0,0,.07-.017v.536a.347.347,0,0,1-.07-.519Z" transform="translate(-160.362)" />
                    <path id="Path_4516" data-name="Path 4516" d="M325.113,13.112a12.7,12.7,0,0,1,6.711,5.2.426.426,0,0,0,.718-.46,13.555,13.555,0,0,0-7.164-5.552.426.426,0,0,0-.266.81Z" transform="translate(-307.69 -11.632)" />
                    <path id="Path_4517" data-name="Path 4517" d="M38.928,17.919a.426.426,0,0,0,.589-.129,12.69,12.69,0,0,1,6.8-5.23.426.426,0,0,0-.26-.812A13.544,13.544,0,0,0,38.8,17.33.426.426,0,0,0,38.928,17.919Z" transform="translate(-36.69 -11.109)" />
                    <path id="Path_4518" data-name="Path 4518" d="M44.9,386.438a12.661,12.661,0,0,1-5.377-4.679.426.426,0,0,0-.718.46,13.513,13.513,0,0,0,5.739,4.993.426.426,0,1,0,.356-.775Z" transform="translate(-36.691 -361.441)" />
                    <path id="Path_4519" data-name="Path 4519" d="M358.811,381.631a.426.426,0,0,0-.589.129,12.669,12.669,0,0,1-5.29,4.638.426.426,0,1,0,.362.772,13.52,13.52,0,0,0,5.646-4.95A.427.427,0,0,0,358.811,381.631Z" transform="translate(-334.089 -361.442)" />
                    <path id="Path_4520" data-name="Path 4520" d="M9.118,152.439v-1.516a2.41,2.41,0,0,0-1.524-2.337L5.9,147.9v-.238a2.219,2.219,0,0,0,.984-1.221,1.2,1.2,0,0,0,.663-2.126v-.752a2.862,2.862,0,0,0-2.858-2.859h-.2a2.862,2.862,0,0,0-2.858,2.859v.749a1.2,1.2,0,0,0,.672,2.13,2.219,2.219,0,0,0,.984,1.22v.235l-1.744.683A2.41,2.41,0,0,0,.01,150.923v1.519a.427.427,0,0,0,.426.427H1.519a.426.426,0,1,0,0-.853H.863v-1.093a1.574,1.574,0,0,1,.958-1.534l.02-.007,1.6-.625.848.826a.426.426,0,0,0,.3.121H4.59a.426.426,0,0,0,.3-.13l.8-.831,1.593.639.019.007a1.574,1.574,0,0,1,.961,1.536v1.093H2.874a.426.426,0,1,0,0,.853H8.691A.429.429,0,0,0,9.118,152.439ZM6.989,145a.416.416,0,0,0,.047.012.347.347,0,0,1-.047.519V145Zm-4.8.541a.347.347,0,0,1-.07-.519.425.425,0,0,0,.07-.017Zm.428-1.722h0l-.044,0-.011,0-.031,0-.016,0-.025.006-.01,0v-.273a2.008,2.008,0,0,1,2.006-2.006h.2a2.008,2.008,0,0,1,2.006,2.006v.269l-.022-.006-.011,0c-.589-.134-.782-.921-.784-.927a.426.426,0,0,0-.761-.156A3.44,3.44,0,0,1,2.614,143.818Zm2.429,4.367-.47.487-.441-.43v-.289a2.215,2.215,0,0,0,.262.016h.387a2.214,2.214,0,0,0,.262-.016v.231Zm-.262-1.067H4.394a1.357,1.357,0,0,1-1.356-1.355v-1.118h0a4.385,4.385,0,0,0,2.28-.881,1.908,1.908,0,0,0,.817.783v1.216A1.357,1.357,0,0,1,4.781,147.118Z" transform="translate(-0.01 -133.29)" />
                    <path id="Path_4521" data-name="Path 4521" d="M346.869,148.585l-1.7-.681v-.238a2.219,2.219,0,0,0,.984-1.221,1.2,1.2,0,0,0,.663-2.126v-.752a2.862,2.862,0,0,0-2.858-2.859h-.2a2.862,2.862,0,0,0-2.858,2.859v.749a1.2,1.2,0,0,0,.672,2.13,2.218,2.218,0,0,0,.984,1.22v.235l-1.744.683a2.41,2.41,0,0,0-1.526,2.338v1.519a.427.427,0,0,0,.426.427h1.083a.426.426,0,1,0,0-.853h-.657v-1.093a1.574,1.574,0,0,1,.958-1.534l.02-.007,1.6-.625.848.826a.426.426,0,0,0,.3.121h.007a.426.426,0,0,0,.3-.13l.8-.831,1.593.639.019.007a1.574,1.574,0,0,1,.961,1.536v1.093h-5.391a.426.426,0,1,0,0,.853h5.817a.429.429,0,0,0,.426-.429v-1.516A2.41,2.41,0,0,0,346.869,148.585ZM346.264,145a.42.42,0,0,0,.047.012.347.347,0,0,1-.047.519Zm-4.8.541a.347.347,0,0,1-.07-.519.424.424,0,0,0,.07-.017Zm.428-1.722h0l-.044,0-.011,0-.031,0-.016,0-.025.006-.01,0v-.273a2.008,2.008,0,0,1,2.006-2.006h.2a2.008,2.008,0,0,1,2.006,2.006v.269l-.022-.006-.011,0c-.589-.134-.782-.921-.784-.927a.426.426,0,0,0-.761-.156A3.44,3.44,0,0,1,341.888,143.817Zm2.429,4.367-.47.487-.441-.43v-.289a2.216,2.216,0,0,0,.262.016h.387a2.215,2.215,0,0,0,.262-.016Zm-.262-1.067h-.387a1.357,1.357,0,0,1-1.356-1.356v-1.118h0a4.389,4.389,0,0,0,2.28-.881,1.907,1.907,0,0,0,.817.783v1.216A1.357,1.357,0,0,1,344.056,147.117Z" transform="translate(-321.393 -133.289)" />
                    <path id="Path_4522" data-name="Path 4522" d="M176.871,289.318l-1.7-.681V288.4a2.219,2.219,0,0,0,.984-1.221,1.2,1.2,0,0,0,.663-2.126V284.3a2.862,2.862,0,0,0-2.858-2.859h-.2A2.862,2.862,0,0,0,170.9,284.3v.749a1.2,1.2,0,0,0,.672,2.13,2.219,2.219,0,0,0,.984,1.22v.235l-1.744.683a2.41,2.41,0,0,0-1.525,2.338v1.519a.427.427,0,0,0,.426.427H170.8a.426.426,0,1,0,0-.853h-.657v-1.093a1.574,1.574,0,0,1,.958-1.534l.02-.007,1.6-.625.848.826a.426.426,0,0,0,.3.121h.007a.426.426,0,0,0,.3-.13l.8-.831,1.593.639.019.007a1.574,1.574,0,0,1,.961,1.536v1.093h-5.391a.426.426,0,1,0,0,.853h5.817a.429.429,0,0,0,.426-.429v-1.516A2.41,2.41,0,0,0,176.871,289.318Zm-.605-3.586a.418.418,0,0,0,.047.012.347.347,0,0,1-.047.519Zm-4.8.541a.347.347,0,0,1-.07-.519.42.42,0,0,0,.07-.017Zm.428-1.722h0l-.044,0-.013,0-.029,0-.02.005-.021.005-.01,0V284.3a2.008,2.008,0,0,1,2.006-2.006h.2a2.008,2.008,0,0,1,2.006,2.006v.269l-.021-.006-.012,0c-.589-.134-.782-.921-.784-.927a.426.426,0,0,0-.761-.156A3.44,3.44,0,0,1,171.891,284.55Zm2.429,4.367-.47.488-.441-.43v-.289a2.218,2.218,0,0,0,.262.016h.387a2.224,2.224,0,0,0,.262-.016Zm-.262-1.067h-.387a1.357,1.357,0,0,1-1.356-1.355v-1.118h0a4.385,4.385,0,0,0,2.28-.881,1.908,1.908,0,0,0,.817.783v1.216A1.357,1.357,0,0,1,174.058,287.849Z" transform="translate(-160.36 -266.6)" />
                </g>
            </g>
        </g>
    </svg>
    , 'Community')

export default CommunityIcon