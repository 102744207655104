import { Box, Button, Modal, Typography, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import Langs from "./Const/Lang";

const ModalLoginAlert = ({ visible, setVisible, lang }) => {
  const matches = useMediaQuery("(min-width:768px)");
  const router = useRouter();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? 500 : "100vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  return (
    <Modal
      open={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ textAlign: "right" }}>
          <Typography
            variant="body2"
            onClick={() => setVisible(false)}
            sx={{ marginLeft: "auto", color: "#FE9292", cursor: "pointer" }}
          >
            {Langs?.luc_khac?.[lang]}
          </Typography>
        </div>
        <img src={"/popupIcon.svg"} />
        <Typography variant="h6">
          {Langs?.chao_mung_ban_den_voi?.[lang]}
        </Typography>
        <Typography variant="h6">
          {Langs?.ket_noi_yeu_thuong?.[lang]}
        </Typography>
        <Typography variant="body2">
          {Langs?.cong_dong_tu_thien_uy_tin_minh_bach?.[lang]}
        </Typography>
        <Button
          size="medium"
          variant="contained"
          className="btn-login"
          onClick={() => router.push({ pathname: "/user/login" })}
          sx={{
            backgroundColor: "#64B5EC",
            width: "100%",
            marginTop: "30px",
          }}
        >
          {Langs?.dang_nhap_hoac_dang_ky_tai_khoan?.[lang]}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalLoginAlert;
