const { createSvgIcon } = require("@mui/material");

const HeartFullIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Group_280" data-name="Group 280" width="20" height="20" viewBox="0 0 29.764 26.302">
        <defs>
            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#ffa9a9" />
                <stop offset="1" stop-color="#fc7777" />
            </linearGradient>
        </defs>
        <path id="Path_15" data-name="Path 15" d="M230.981,159.578a8.074,8.074,0,0,0-11.417,0l-1.1,1.1-1.1-1.1A8.074,8.074,0,1,0,205.952,171l1.1,1.1,11.422,11.422,11.417-11.422,1.1-1.1a8.07,8.07,0,0,0,0-11.421Z" transform="translate(-203.585 -157.214)" fill="url(#linear-gradient)" />
    </svg>,
    "heartFull"
);

export default HeartFullIcon