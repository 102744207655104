const { createSvgIcon } = require("@mui/material");

const MarketIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" id="groceries" viewBox="0 0 32.091 40.912">
        <path id="Path_4711" data-name="Path 4711" d="M9.959,18.751a.746.746,0,0,1-.636-.355,2.936,2.936,0,0,0-2.3-1.57.766.766,0,0,1-.769-.723.746.746,0,0,1,.723-.769,4.424,4.424,0,0,1,3.613,2.28.746.746,0,0,1-.634,1.138Z" transform="translate(-5.998 -3.889)" fill="#436b1c" />
        <g id="Group_288" data-name="Group 288" transform="translate(4.46 0.322)">
            <path id="Path_4712" data-name="Path 4712" d="M26.661,5.033a2.8,2.8,0,0,0-2.787-2.6h-.013a1.731,1.731,0,0,0-.06-.7A1.821,1.821,0,0,0,22.3.444a1.794,1.794,0,0,0-1.483.507,1.99,1.99,0,0,0-3.332,1.478s0,.007,0,.01a2.586,2.586,0,0,0-2.325,3.193,2.991,2.991,0,0,0-2.467,4.489,2.188,2.188,0,0,0-.119,3.284c1.8-1.471,3.691,3.449,5.918,7.376.74,1.3,8.73-.085,9.367.725C28.581,20.855,29.05,9.5,29.07,8.5A3.6,3.6,0,0,0,26.661,5.033Z" transform="translate(-11.886 -0.431)" fill="#88c057" />
        </g>
        <path id="Path_4713" data-name="Path 4713" d="M32.251,19.8l.532-.319a3.108,3.108,0,0,1,3.2,0l.532.319a3.108,3.108,0,0,0,3.2,0l1.5-.669c.221-.616.375-1.063.436-1.261a52.051,52.051,0,0,0,2.43-14c.049-1.906-1.527-4.879-4.146-3.528-2.574,1.327-4.417,6.416-5.713,8.833a52.645,52.645,0,0,0-4.332,10.974A3.1,3.1,0,0,0,32.251,19.8Z" transform="translate(-11.994 0.001)" fill="#eeaf4b" />
        <path id="Path_4714" data-name="Path 4714" d="M6.766,23.211l1,.6a3.641,3.641,0,0,0,3.746,0l.258-.155a3.108,3.108,0,0,1,3.2,0l.532.319A3.1,3.1,0,0,0,17.4,24.4a6.439,6.439,0,0,0-.423-4.776c-2.49-4.392-4.561-3.5-6.553-1.59a3.264,3.264,0,0,1-1.358.779c-2.185.625-3.8,1.646-2.881,4.429A.526.526,0,0,1,6.766,23.211Z" transform="translate(-5.912 -4.175)" fill="#ed3f32" />
        <path id="Path_4715" data-name="Path 4715" d="M35.338,22.972a.746.746,0,0,1-.706-.987,2.815,2.815,0,0,1,3.558-1.755,1.314,1.314,0,0,0,1.666-.821.746.746,0,1,1,1.414.48,2.81,2.81,0,0,1-3.558,1.755,1.316,1.316,0,0,0-1.666.821A.748.748,0,0,1,35.338,22.972Z" transform="translate(-13.187 -4.795)" fill="#a46f3e" />
        <path id="Path_4716" data-name="Path 4716" d="M37.7,16.005A.746.746,0,0,1,37,15.018a2.8,2.8,0,0,1,3.558-1.755,1.312,1.312,0,0,0,1.666-.822.746.746,0,1,1,1.413.48,2.811,2.811,0,0,1-3.558,1.756,1.312,1.312,0,0,0-1.666.821A.746.746,0,0,1,37.7,16.005Z" transform="translate(-13.787 -3.027)" fill="#a46f3e" />
        <path id="Path_4717" data-name="Path 4717" d="M40.069,9.184a.754.754,0,0,1-.24-.04.746.746,0,0,1-.466-.947,2.468,2.468,0,0,1,3.123-1.54.968.968,0,0,0,1.231-.605.746.746,0,1,1,1.414.48,2.461,2.461,0,0,1-3.124,1.54.97.97,0,0,0-1.23.607A.748.748,0,0,1,40.069,9.184Z" transform="translate(-14.387 -1.406)" fill="#a46f3e" />
        <g id="Group_289" data-name="Group 289" transform="translate(11.545 5.972)">
            <path id="Path_4718" data-name="Path 4718" d="M22.865,12.549c-.705.938-1.818,2.049-1.39,4.01l1.152,5.273h0l.532-.319a3.108,3.108,0,0,1,3.2,0l.532.319a3.042,3.042,0,0,0,.841.346,53.775,53.775,0,0,1,3.024-8.343,4.925,4.925,0,0,0-2.707-2.423l-.015-.008a1.041,1.041,0,0,1-.175-1.594.845.845,0,0,0,.187-.731l-.09-.412a.846.846,0,0,0-1-.644l-4.535.99a.846.846,0,0,0-.644,1l.09.412a.838.838,0,0,0,.49.591,1.029,1.029,0,0,1,.606,1.384A1.154,1.154,0,0,1,22.865,12.549Z" transform="translate(-21.38 -8.002)" fill="#dae7ef" />
        </g>
        <path id="Path_4719" data-name="Path 4719" d="M27.54,10.075,22.407,11.2a1.059,1.059,0,0,1-1.256-.805l-.074-.337A1.059,1.059,0,0,1,21.882,8.8l5.134-1.122a1.059,1.059,0,0,1,1.256.805l.074.337A1.058,1.058,0,0,1,27.54,10.075Z" transform="translate(-9.752 -1.941)" fill="#fff" />
        <path id="Path_4720" data-name="Path 4720" d="M36.438,25.248l-1.3,18.679a3.507,3.507,0,0,1-3.5,3.328H10.684a3.507,3.507,0,0,1-3.5-3.328L5.911,25.861a.531.531,0,0,1,.8-.483l1,.6a3.641,3.641,0,0,0,3.746,0l.258-.155a3.108,3.108,0,0,1,3.2,0l.532.319a3.108,3.108,0,0,0,3.2,0l.532-.319a3.108,3.108,0,0,1,3.2,0l.532.319a3.108,3.108,0,0,0,3.2,0l.532-.319a3.108,3.108,0,0,1,3.2,0l.532.319a3.108,3.108,0,0,0,3.2,0l2.528-1.123A.235.235,0,0,1,36.438,25.248Z" transform="translate(-5.91 -6.342)" fill="#fbd490" />
        <path id="Path_4721" data-name="Path 4721" d="M15.88,41.493H10.656a.746.746,0,1,1,0-1.493H15.88a.746.746,0,1,1,0,1.493Z" transform="translate(-6.925 -10.147)" fill="#e0b877" />
        <path id="Path_4722" data-name="Path 4722" d="M17.373,45.493H10.656a.746.746,0,1,1,0-1.493h6.717a.746.746,0,1,1,0,1.493Z" transform="translate(-6.925 -11.162)" fill="#e0b877" />
        <path id="Path_4723" data-name="Path 4723" d="M18.866,49.493H10.656a.746.746,0,0,1,0-1.493h8.209a.746.746,0,0,1,0,1.493Z" transform="translate(-6.925 -12.177)" fill="#e0b877" />
        <path id="Path_4724" data-name="Path 4724" d="M27.149,49.493H25.656a.746.746,0,0,1,0-1.493h1.493a.746.746,0,1,1,0,1.493Z" transform="translate(-10.73 -12.177)" fill="#e0b877" />
    </svg>
    , 'Market')

export default MarketIcon