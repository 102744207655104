import axios from "axios";
import _ from "lodash";
import cookies from "next-cookies";

const getToken = () => {
  return cookies("token")?.token ?? "";
};

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_NOTI + "app/notifies/",
});

export const getNotifications = (params) => {
  const req = instance.get(`/all`, {
    params: _.pickBy(params, _.identify),
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const readAllNoti = (data) => {
  const req = instance.put(`/read-all`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const getCountUnread = (params) => {
  const req = instance.get(`/count-unread`, {
    params: _.pickBy(params, _.identify),
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const getNotificationById = (id, params) => {
  const req = instance.get(`/${id}`, {
    params: _.pickBy(params, _.identify),
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const readOneNoti = (id, data) => {
  const req = instance.put(`/${id}/read`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const signUpToken = (data) => {
  const req = instance.post("/signup-token", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const clearToken = (data) => {
  const req = instance.put(`/clear-token`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const getNotiCountNew = (params) => {
  const req = instance.get(`/count-news`, {
    params: _.pickBy(params, _.identify),
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return req;
};

export const readNotiCountNew = (data) => {
  const req = instance.put(
    `/count-news?member_id=${data?.member_id}`,
    {},
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
  return req;
};
