const SUB_TYPE_NEWS_ALL = {
  id: 2,
  key: "2",
  parent_id: 1,
  type: 1,
  vi: "Trang chủ",
  en: "Home",
};

const SUB_TYPE_NEWS_PRIVATE = {
  id: 16,
  key: "16",
  parent_id: 1,
  type: 2,
  vi: "Trang cá nhân",
  en: "Personal page",
};

const TYPE_NEWS = {
  id: 1,
  key: "1",
  vi: "Bảng tin",
  en: "News Feed",
  color: "#77dd79",
  title: {
    vi: "Loại tin",
    en: "Type of posts",
  },
  link: "/community",
  subCate: [SUB_TYPE_NEWS_ALL, SUB_TYPE_NEWS_PRIVATE],
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 26.743">
      <g id="home" transform="translate(0.001 -27.797)">
        <g
          id="Group_33"
          data-name="Group 33"
          transform="translate(-0.001 27.798)"
        >
          <g id="Group_32" data-name="Group 32">
            <path
              id="Path_20"
              data-name="Path 20"
              d="M29.68,40.164,15.46,27.974a.689.689,0,0,0-.921,0L.318,40.164A1.018,1.018,0,0,0,.15,41.439a.709.709,0,0,0,1.089.2L15,29.841l13.76,11.8a.7.7,0,0,0,.46.176.748.748,0,0,0,.63-.374A1.017,1.017,0,0,0,29.68,40.164Z"
              transform="translate(0.001 -27.798)"
            />
          </g>
        </g>
        <g
          id="Group_35"
          data-name="Group 35"
          transform="translate(3.872 37.276)"
        >
          <g id="Group_34" data-name="Group 34">
            <path
              id="Path_21"
              data-name="Path 21"
              d="M77.965,232.543a.841.841,0,0,0-.742.913v14.526H71.289v-7.929c0-2.516-1.664-4.563-3.709-4.563s-3.709,2.047-3.709,4.563v7.929H57.936V233.456a.758.758,0,1,0-1.484,0v15.439a.841.841,0,0,0,.742.913h7.418a.825.825,0,0,0,.739-.841c0-.021,0-.045,0-.071v-8.842a2.274,2.274,0,1,1,4.451,0v8.842c0,.026,0,.049,0,.07a.825.825,0,0,0,.739.842h7.418a.841.841,0,0,0,.742-.913V233.456A.841.841,0,0,0,77.965,232.543Z"
              transform="translate(-56.452 -232.543)"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
};

const SUB_TYPE_GIFT_TAKE = {
  id: 5,
  key: "5",
  parent_id: 4,
  type: 4,
  vi: "Cần giúp đỡ",
  en: "Need help",
};

const SUB_TYPE_GIFT_GIVE = {
  id: 6,
  key: "6",
  parent_id: 4,
  type: 3,
  vi: "Tặng người khác",
  en: "Give away",
};

const TYPE_GIFT = {
  id: 4,
  key: "4",
  vi: "Cho tặng",
  en: "Donate",
  color: "#89d0f2",
  title: {
    vi: "Nhận khi bạn cần, cho khi bạn có",
    en: "Take what you need, give what you can",
  },
  link: "/gift",
  subCate: [SUB_TYPE_GIFT_TAKE, SUB_TYPE_GIFT_GIVE],
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.027"
      height="21"
      viewBox="0 0 21.027 21"
    >
      <g id="Present_Box_1" transform="translate(-0.485 -0.499)">
        <path
          id="Path_10491"
          data-name="Path 10491"
          d="M20.53,2.818H17.512a1.962,1.962,0,0,0-.282-1.473A1.007,1.007,0,0,0,16.439,1a7.272,7.272,0,0,0-3.845,1.818H9.485A7.378,7.378,0,0,0,5.53,1a1.01,1.01,0,0,0-.8.345,1.964,1.964,0,0,0-.273,1.473H1.44a.455.455,0,0,0-.455.455V6.909a.455.455,0,0,0,.455.455H7.612l-.491.909H2.349a.455.455,0,0,0-.455.455V20.545A.455.455,0,0,0,2.349,21H9.621a.455.455,0,0,0,.455-.455V8.981l.909-1.818.909,2.382v11a.455.455,0,0,0,.455.455h7.272a.455.455,0,0,0,.455-.455V8.727a.455.455,0,0,0-.455-.455H14.557l-.364-.909h6.363a.455.455,0,0,0,.455-.455V3.273a.455.455,0,0,0-.454-.455ZM13.821,8.891h0l-.436-.145a.455.455,0,0,0-.573.291l-.064.182L11.594,6.309a1.832,1.832,0,0,0,.564-.336l.618.3.309.773h0Zm-4.936.464-.245-.409a.454.454,0,0,0-.536-.2l-.273.091L9.167,6.354l.664-.3a1.8,1.8,0,0,0,.473.273Zm3.009-4.718a.909.909,0,1,1-.909-.909A.909.909,0,0,1,11.894,4.636Zm4.654-2.727c.145.173.073.818,0,1.264h0v.145a3.565,3.565,0,0,1-.064.464,3.033,3.033,0,0,0,0,.364v.291A4.073,4.073,0,0,1,16.2,6.345c-.282.318-1.682-.264-2.873-.8h0L12.7,5.254a1.75,1.75,0,0,0,0-1.291,7.045,7.045,0,0,1,3.736-2.054A.142.142,0,0,1,16.548,1.909Zm-11.127,0a.142.142,0,0,1,.109,0,7.091,7.091,0,0,1,3.8,1.982,1.819,1.819,0,0,0,0,1.418C8,5.9,6.067,6.645,5.8,6.382S5.53,4.991,5.53,4.473V4.182a3.033,3.033,0,0,0,0-.364,1.214,1.214,0,0,0-.064-.464V3.209h0A2.213,2.213,0,0,1,5.421,1.909Z"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
        />
      </g>
    </svg>
  ),
};

const SUB_TYPE_MOVE_TAKE = {
  id: 8,
  key: "8",
  parent_id: 12,
  type: 4,
  vi: "Tìm người vận chuyển",
  en: "Find a carrier",
};

const SUB_TYPE_MOVE_GIVE = {
  id: 9,
  key: "9",
  parent_id: 12,
  type: 3,
  vi: "Nhận vận chuyển",
  en: "Carrier",
};

const TYPE_MOVE = {
  id: 12,
  key: "12",
  vi: "Vận chuyển",
  en: "Transports",
  color: "#89d0f2",
  link: "/move",
  subCate: [SUB_TYPE_MOVE_TAKE, SUB_TYPE_MOVE_GIVE],
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.677"
      height="21.001"
      viewBox="0 0 64.677 21.001"
    >
      <g id="Layer_x0020_1" transform="translate(0.5 0.5)">
        <path
          id="Path_10493"
          data-name="Path 10493"
          d="M1.444,1.846H22.932a.51.51,0,0,1,.51.51V18.234a.51.51,0,0,1-.51.51H11.892a3.53,3.53,0,0,0-7.057,0H1.444a.51.51,0,0,1-.51-.51V2.357a.51.51,0,0,1,.51-.51ZM5.334,9.5a.255.255,0,0,0,0,.51H18.622a.255.255,0,0,0,0-.51Zm0-3.917a.255.255,0,1,0,0,.51H18.622a.255.255,0,0,0,0-.51Z"
          transform="translate(-0.933 -1.846)"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
        />
        <path
          id="Path_10494"
          data-name="Path 10494"
          d="M6.758,4.853a3.019,3.019,0,1,0,.884,2.135,3.01,3.01,0,0,0-.884-2.135ZM5.4,6.988a.777.777,0,1,1-.777-.777A.777.777,0,0,1,5.4,6.988Z"
          transform="translate(2.807 9.993)"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
          fillRule="evenodd"
        />
        <path
          id="Path_10495"
          data-name="Path 10495"
          d="M4.745,6.988A3.02,3.02,0,1,0,5.63,4.853,3.01,3.01,0,0,0,4.745,6.988Zm3.8,0a.777.777,0,1,1-.777-.777A.777.777,0,0,1,8.542,6.988Z"
          transform="translate(20.328 9.993)"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
          fillRule="evenodd"
        />
        <path
          id="Path_10496"
          data-name="Path 10496"
          d="M9.615,12.16a3.53,3.53,0,0,1,3.529,3.446h1.545a.51.51,0,0,0,.51-.51V7.5a5.251,5.251,0,0,0-1.393-3.584,4.628,4.628,0,0,0-3.39-1.51H4.541V15.1a1.016,1.016,0,0,1-.128.494.511.511,0,0,0,.128.016H6.086A3.53,3.53,0,0,1,9.615,12.16Zm2.639-5.223V9.8H8.433V5.21h2.212a1.676,1.676,0,0,1,1.609,1.726Z"
          transform="translate(18.478 1.292)"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
          fillRule="evenodd"
        />
      </g>
    </svg>
  ),
};

const SUB_TYPE_JOB_TAKE = {
  id: 11,
  key: "11",
  parent_id: 25,
  type: 4,
  vi: "Cần tìm việc",
  en: "Look for a job",
};

const SUB_TYPE_JOB_GIVE = {
  id: 7,
  key: "7",
  parent_id: 25,
  type: 3,
  vi: "Tuyển dụng",
  en: "Recruitment",
};

const TYPE_JOB = {
  id: 25,
  key: "25",
  vi: "Công việc",
  en: "Jobs",
  color: "#76dd78",
  link: "/job",
  subCate: [SUB_TYPE_JOB_TAKE, SUB_TYPE_JOB_GIVE],
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.333"
      height="21"
      viewBox="0 0 24.333 21"
    >
      <path
        id="_1608860_suitcase_icon"
        data-name="1608860_suitcase_icon"
        d="M8.333,131.333H15v-1.667H8.333Zm-4.583,0V148H2.917a2.807,2.807,0,0,1-2.057-.859A2.807,2.807,0,0,1,0,145.083V134.25a2.807,2.807,0,0,1,.859-2.057,2.807,2.807,0,0,1,2.057-.859Zm14.583,0V148H5V131.333H6.667V129.25A1.245,1.245,0,0,1,7.917,128h7.5a1.245,1.245,0,0,1,1.25,1.25v2.083Zm5,2.917v10.833A2.934,2.934,0,0,1,20.417,148h-.833V131.333h.833a2.934,2.934,0,0,1,2.917,2.917Z"
        transform="translate(0.5 -127.5)"
        fill="none"
        stroke="#515151"
        strokeWidth="1"
      />
    </svg>
  ),
};

const SUB_TYPE_PLACE_TAKE = {
  id: 14,
  key: "14",
  parent_id: 35,
  type: 4,
  vi: "Tìm chỗ ở",
  en: "Find accommodation",
};

const SUB_TYPE_PLACE_GIVE = {
  id: 15,
  key: "15",
  parent_id: 35,
  type: 3,
  vi: "Hỗ trợ chỗ ở",
  en: "Accommodation support",
};

const TYPE_PLACE = {
  id: 35,
  key: "35",
  vi: "Chỗ ở",
  en: "Accommodation",
  color: "#76dd78",
  link: "/place",
  subCate: [SUB_TYPE_PLACE_TAKE, SUB_TYPE_PLACE_GIVE],
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.103"
      height="20.5"
      viewBox="0 0 22.103 20.5"
    >
      <g
        id="_353416_home_icon"
        data-name="353416_home_icon"
        transform="translate(-8.501 -7.103)"
      >
        <path
          id="Path_10489"
          data-name="Path 10489"
          d="M13.068,25.512v7.726a.4.4,0,0,0,.426.352H23.546v-7h4.722v7h2.809a.394.394,0,0,0,.426-.352V25.512l-9.212-7.567Zm7.5,4.341H16.3V26.594h4.261Z"
          transform="translate(-2.733 -6.487)"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
        />
        <path
          id="Path_10490"
          data-name="Path 10490"
          d="M30.26,16.247,19.553,7.1,14.5,11.416v-1.9a.952.952,0,0,0-1.881,0v3.5L8.846,16.247a.9.9,0,0,0,0,1.42,1.324,1.324,0,0,0,1.663,0l9.045-7.724L28.6,17.667a1.321,1.321,0,0,0,1.663,0A.9.9,0,0,0,30.26,16.247Z"
          transform="translate(0)"
          fill="#515151"
        />
      </g>
    </svg>
  ),
};

const SUB_TYPE_MARKET_TAKE = {
  id: 17,
  key: "17",
  parent_id: 3,
  type: 4,
  vi: "Tìm nhà cung cấp",
  en: "Find a supplier",
};

const SUB_TYPE_MARKET_GIVE = {
  id: 18,
  key: "18",
  parent_id: 3,
  type: 3,
  vi: "Nhà cung cấp",
  en: "Supplier",
};

const TYPE_MARKET = {
  id: 3,
  key: "3",
  vi: "Chợ yêu thương",
  en: "Cherish market",
  color: "#76dd78",
  link: "/market",
  subCate: [SUB_TYPE_MARKET_TAKE, SUB_TYPE_MARKET_GIVE],
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.223"
      height="21"
      viewBox="0 0 22.223 21"
    >
      <g id="Solid" transform="translate(-1.5 -1.5)">
        <path
          id="Path_10492"
          data-name="Path 10492"
          d="M23.223,12.435v1.3a.9.9,0,0,1-.923.87H2.923A.9.9,0,0,1,2,13.739v-1.3a.9.9,0,0,1,.923-.87H22.3a.9.9,0,0,1,.923.87ZM3.082,15.478H22.14l-.67,5.37A1.359,1.359,0,0,1,20.1,22H5.127a1.359,1.359,0,0,1-1.375-1.151Zm13.681,4.348a.462.462,0,0,0,.923,0V16.783a.462.462,0,0,0-.923,0Zm-4.614,0a.462.462,0,0,0,.923,0V16.783a.462.462,0,0,0-.923,0Zm-4.614,0a.462.462,0,0,0,.923,0V16.783a.462.462,0,0,0-.923,0ZM5.129,7.241a2.575,2.575,0,0,1-1.283-2.2v-1.3A.449.449,0,0,1,4.307,3.3h.461A2.7,2.7,0,0,1,7.536,5.913v.9A4.01,4.01,0,0,1,11.227,10.7h-8.3A3.891,3.891,0,0,1,5.129,7.241Zm2.408.411a.436.436,0,1,0,0,.87,1.371,1.371,0,0,1,1.34.978.465.465,0,0,0,.562.313.433.433,0,0,0,.332-.53A2.286,2.286,0,0,0,7.536,7.652ZM9.382,6.441v-1.4A3.144,3.144,0,0,1,12.611,2a3.144,3.144,0,0,1,3.23,3.043V10.7H12.15c0-.147-.01-.291-.023-.435h1.407a.436.436,0,1,0,0-.87H11.953a4.874,4.874,0,0,0-2.571-2.95Zm1.845-.963a.449.449,0,0,0,.461.435h1.845a.436.436,0,1,0,0-.87H11.689a.449.449,0,0,0-.461.435Zm.923,2.174a.449.449,0,0,0,.461.435h.923a.436.436,0,1,0,0-.87h-.923a.449.449,0,0,0-.461.435ZM22.3,10.7H16.764V7.217h4.152a1.347,1.347,0,0,1,1.384,1.3ZM20.454,8.522a.449.449,0,0,0-.461-.435H18.148a.436.436,0,1,0,0,.87h1.845a.449.449,0,0,0,.461-.435Z"
          fill="none"
          stroke="#515151"
          strokeWidth="1"
        />
      </g>
    </svg>
  ),
};

const type_topic = [
  TYPE_NEWS,
  TYPE_GIFT,
  TYPE_MOVE,
  TYPE_JOB,
  TYPE_PLACE,
  TYPE_MARKET,
];

const findTypeTopic = (type, field) => {
  let obj = type_topic.find((o) => o.id * 1 === type * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const sub_type_topic = [
  SUB_TYPE_NEWS_ALL,
  SUB_TYPE_NEWS_PRIVATE,
  SUB_TYPE_GIFT_GIVE,
  SUB_TYPE_GIFT_TAKE,
  SUB_TYPE_MOVE_GIVE,
  SUB_TYPE_MOVE_TAKE,
  SUB_TYPE_JOB_GIVE,
  SUB_TYPE_JOB_TAKE,
  SUB_TYPE_PLACE_GIVE,
  SUB_TYPE_PLACE_TAKE,
  SUB_TYPE_MARKET_GIVE,
  SUB_TYPE_MARKET_TAKE,
];

const findSubTypeTopic = (sub_type, field) => {
  let obj = sub_type_topic.find((o) => o.id * 1 === sub_type * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const filterSubCate = (array, field, typeId) => {
  if (array?.length > 0 && field) {
    let obj = array.find((a) => a[field] * 1 === typeId * 1);
    return obj ?? undefined;
  }
  return undefined;
};

const CATE_PUBLIC = {
  id: 1,
  key: "1",
  vi: "Trang chủ & Trang cá nhân",
  en: "Home & Personal page",
  color: "#77dd79",
};

const CATE_PRIVATE = {
  id: 2,
  key: "2",
  vi: "Trang cá nhân",
  en: "Personal page",
  color: "#89d0f2",
};

const CATE_GIVE = {
  id: 3,
  key: "3",
  vi: "Giúp đỡ người khác",
  en: "Give away",
  color: "#77dd79",
};

const CATE_TAKE = {
  id: 4,
  key: "4",
  vi: "Cần giúp đỡ",
  en: "Need help",
  color: "#FED779",
};

const cate_topic = [CATE_PUBLIC, CATE_PRIVATE, CATE_GIVE, CATE_TAKE];

const findCateTopic = (cate, field) => {
  let obj = cate_topic.find((o) => o.id * 1 === cate * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const STATUS_ACTIVE = {
  id: 1,
  key: "1",
  vi: "Đang hoạt động",
  en: "Active",
  color: "#77dd79",
};

const STATUS_INACTIVE = {
  id: -1,
  key: "-1",
  vi: "Đang tạm ngưng",
  en: "Pausing",
  color: "#77dd79",
};

const STATUS_CLOSED = {
  id: -2,
  key: "-2",
  vi: "Đã hết hạn",
  en: "Expired",
  color: "#77dd79",
};

const status_topic = [STATUS_ACTIVE, STATUS_INACTIVE, STATUS_CLOSED];

const findStatusTopic = (status, field) => {
  let obj = status_topic.find((o) => o.id * 1 === status * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

const AVAILABLE_ACTIVE = {
  id: 1,
  key: "1",
  vi: "Tin còn hạn",
  en: "Valid post",
  color: "#77dd79",
};

const AVAILABLE_INACTIVE = {
  id: -1,
  key: "-1",
  vi: "Tin hết hạn",
  en: "Expired",
  color: "#77dd79",
};

const available_topic = [AVAILABLE_ACTIVE, AVAILABLE_INACTIVE];

const findAvailableTopic = (available, field) => {
  let obj = available_topic.find((o) => o.id * 1 === available * 1);
  return obj ? (field && obj?.[field] ? obj[field] : obj) : undefined;
};

export {
  TYPE_NEWS,
  TYPE_MARKET,
  TYPE_GIFT,
  TYPE_JOB,
  TYPE_MOVE,
  TYPE_PLACE,
  SUB_TYPE_NEWS_ALL,
  SUB_TYPE_NEWS_PRIVATE,
  SUB_TYPE_GIFT_GIVE,
  SUB_TYPE_GIFT_TAKE,
  SUB_TYPE_MOVE_GIVE,
  SUB_TYPE_MOVE_TAKE,
  SUB_TYPE_JOB_GIVE,
  SUB_TYPE_JOB_TAKE,
  SUB_TYPE_PLACE_GIVE,
  SUB_TYPE_PLACE_TAKE,
  SUB_TYPE_MARKET_GIVE,
  SUB_TYPE_MARKET_TAKE,
  findTypeTopic,
  filterSubCate,
  findSubTypeTopic,
  type_topic,
  sub_type_topic,
  CATE_PUBLIC,
  CATE_PRIVATE,
  CATE_GIVE,
  CATE_TAKE,
  findCateTopic,
  cate_topic,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_CLOSED,
  findStatusTopic,
  status_topic,
  AVAILABLE_ACTIVE,
  AVAILABLE_INACTIVE,
  findAvailableTopic,
  available_topic,
};
