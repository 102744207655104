import {
  Stack,
  Typography,
  Button,
  Avatar,
  Paper,
  Grid,
  Chip,
  createSvgIcon,
} from "@mui/material";
import { findCate } from "common/Article";
import { findType } from "common/User";
import React, { useState } from "react";
import { useRouter } from "next/router";
import { LimitRowContent } from "components";
import {
  filterSubCate,
  findCateTopic,
  findStatusTopic,
  findSubTypeTopic,
  findTypeTopic,
  TYPE_NEWS,
} from "components/Common/Topic";
import HeartFullIcon from "common/Icons/HeartFullIcon";
import cookies from "next-cookies";
import numeral from "numeral";
import ModalLoginAlert from "components/ModalLoginAlert";

const OwnerType = ({ type, findData }) => (
  <div
    style={{
      backgroundColor: findData(type, "color"),
      borderRadius: "4px",
      textAlign: "center",
      margin: 0,
      width: "fit-content",
      padding: "0 5px",
      fontSize: "12px",
    }}
  >
    {findData(type, "label")}
  </div>
);

const ArticleOwner = ({
  src,
  avatarAlt,
  name,
  categoryId,
  typeText,
  typeVariant = "container",
  typeSx = { backgroundColor: "#89D0F2" },
  type,
  creatorId,
  creatorType,
  totalBeingFollowed,
  displayHeart,
  notClickUser,
}) => {
  const router = useRouter();
  let lang = cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  let token = cookies("token")?.token;
  const [visible, setVisible] = useState(false);

  return (
    <Grid
      wrap="nowrap"
      container
      sx={{ width: "100%" }}
      className="article-owner"
      direction="row"
      spacing={1}
    >
      <Grid item>
        <Paper sx={{ borderRadius: "100%", width: 50 }} elevation={3}>
          <Avatar
            elevation={1}
            src={src}
            alt={avatarAlt || undefined}
            sx={{
              width: 50,
              height: 50,
              cursor: "pointer",
            }}
            onClick={() =>
              notClickUser
                ? null
                : token?.length > 0
                ? router.push(`/user/${creatorId}`)
                : setVisible(true)
            }
          />
        </Paper>
      </Grid>
      <Grid item xs={9}>
        <Stack sx={{ width: "100%" }} spacing={1}>
          <LimitRowContent
            row={1}
            title={name}
            variant="subtitle2"
            sx={{
              textTransform: "uppercase",
            }}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                notClickUser
                  ? null
                  : token?.length > 0
                  ? router.push(`/user/${creatorId}`)
                  : setVisible(true)
              }
            >
              {name}
            </span>
          </LimitRowContent>
          <Stack direction="row" spacing={1}>
            <Chip
              size="small"
              label={findType(
                creatorType,
                lang ?? process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE
              )}
              sx={{
                height: "20px",
                fontSize: "0.725rem",
                backgroundColor: findType(creatorType, "color"),
                borderRadius: "4px",
              }}
            />
            {displayHeart && (
              <Typography
                variant="body2"
                sx={{ marginLeft: "20px", height: "18px" }}
              >
                <HeartFullIcon
                  style={{ fontSize: "18px", marginRight: "5px" }}
                />
                <span style={{ verticalAlign: "super" }}>
                  {totalBeingFollowed
                    ? numeral(totalBeingFollowed).format("0,0")
                    : 0}
                </span>
              </Typography>
            )}
            {categoryId && type && !displayHeart && (
              <Chip
                size="small"
                label={
                  filterSubCate(
                    findTypeTopic(categoryId, "subCate"),
                    "type",
                    type
                  )?.[lang] ?? ""
                }
                sx={{
                  margin: "5px 0",
                  height: "20px",
                  fontSize: "0.725rem",
                  backgroundColor: findCateTopic(type, "color"),
                  borderRadius: "4px",
                }}
              />
            )}
          </Stack>
        </Stack>
      </Grid>

      <ModalLoginAlert
        visible={visible}
        setVisible={(vis) => setVisible(vis)}
        lang={lang}
      />
    </Grid>
  );
};

export default ArticleOwner;
