import { Grid, Typography } from "@mui/material";
import React from "react";
import ArticleOwner from "./ArticleOwner";
import { findType } from "common/User";

const ArticleHeader = ({
  area,
  type,
  creatorType,
  avatarUrl,
  avatarAlt,
  name,
  typeVariant,
  size,
  children,
  creatorId,
  displayHeart,
  totalBeingFollowed,
  notClickUser,
  ...rest
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="end"
      wrap="nowrap"
      mb={1.5}
    >
      <Grid item xs={children ? 9 : 12}>
        <ArticleOwner
          src={avatarUrl}
          avatarAlt={avatarAlt}
          name={name ? name : "-"}
          type={type}
          typeVariant={typeVariant}
          typeSx={{ backgroundColor: findType(type, "color") }}
          size={size}
          categoryId={rest.categoryId}
          creatorId={creatorId}
          creatorType={creatorType}
          displayHeart={displayHeart}
          totalBeingFollowed={totalBeingFollowed}
          notClickUser={notClickUser}
        />
      </Grid>
      {
        // area ?
        // <Grid item xs={3}>
        //     <Typography align="right" title={area} noWrap variant="body2">{area}</Typography>
        // </Grid>
        children ? (
          <Grid align="right" item xs={3}>
            {children}
          </Grid>
        ) : undefined
      }
    </Grid>
  );
};

export default ArticleHeader;
