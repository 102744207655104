import { Avatar, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import ArticleHeader from "./ArticleHeader";
import Image from "next/image";
import { Box } from "@mui/system";
import styles from "./Article.module.scss";
import ArticleFooter from "./ArticleFooter";
import ArticleBanner from "./ArticleBanner";
import { LimitRowContent } from "components";

const Article = ({
  banner,
  bannerAlt,
  title,
  content,
  date,
  children,
  paperSx,
  notClickUser,
  ...rest
}) => {
  const [shadow, setShadow] = useState(rest?.shadow ?? 1);

  const onMouseOver = () => setShadow(4);
  const onMouseOut = () => setShadow(1);
  return (
    <Paper
      elevation={shadow}
      sx={{ borderRadius: "10px", cursor: "pointer", ...paperSx }}
      className="article-wrapper"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children ? (
        children
      ) : (
        <>
          {banner && <ArticleBanner banner={banner} bannerAlt={bannerAlt} />}
          <Box mt={1.3} px={1.5} pb={1.2}>
            <ArticleHeader notClickUser={notClickUser} {...rest} />
            <LimitRowContent
              row={2}
              mt={1}
              fontSize={18}
              variant="h6"
              lineHeight={1.45}
            >
              {title}
            </LimitRowContent>
            {content && (
              <Typography mt={1} fontSize={14} variant="caption">
                {content}
              </Typography>
            )}
            <ArticleFooter date={date} area={rest.area} />
          </Box>
        </>
      )}
    </Paper>
  );
};

export default Article;
