import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const LimitRowContent = styled(Typography)(({ theme, row = 2, lineHeight, minHeight }) => {
    return ({
        display: "-webkit-box",
        WebkitLineClamp: row,
        WebkitBoxOrient: "vertical",
        overflow: 'hidden',
        minHeight: minHeight ? minHeight : row * 24 + 'px !important',
        lineHeight: lineHeight ? lineHeight : 1.6
    })
})

export default LimitRowContent;