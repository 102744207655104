import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  createSvgIcon,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Modal,
  NoSsr,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getNotiCountNew,
  readNotiCountNew,
  signUpToken,
} from "actions/notificationAPI";
import "animate.css";
import { slides } from "common/Guideline";
import CarIcon from "common/Icons/CarIcon";
import CommunityIcon from "common/Icons/CommunityIcon";
import GiftIcon from "common/Icons/GiftIcon";
import HomeColorIcon from "common/Icons/HomeColorIcon";
import HomeIcon from "common/Icons/HomeIcon";
import JobIcon from "common/Icons/JobIcon";
import MarketIcon from "common/Icons/MarketIcon";
import NotiIcon from "common/Icons/NotiIcon";
import PostIcon from "common/Icons/PostIcon";
import { TYPE_HELPER } from "common/User";
import {
  AVAILABLE_ACTIVE,
  CATE_PUBLIC,
  TYPE_GIFT,
  TYPE_JOB,
  TYPE_MARKET,
  TYPE_MOVE,
  TYPE_NEWS,
  TYPE_PLACE,
} from "components/Common/Topic";
import { TYPE_ORGANIZATION } from "components/Common/User";
import Langs from "components/Const/Lang";
import LanguageHook from "components/Hook/Language";
import UseMobileDetect from "components/Hook/UseMobileDetect";
import ModalLoginAlert from "components/ModalLoginAlert";
import firebase from "firebase";
import moment from "moment-mini";
import cookies from "next-cookies";
import Head from "next/head";
import { useRouter } from "next/router";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { renderURL } from "utils";

const defaultImg =
  process.env.NEXT_PUBLIC_MEDIA_API + process.env.NEXT_PUBLIC_DEFAULT_THUMB;
let isDisplayPopup = false;
let globalCountNoti = null;

const Header = ({ token, authCookie, filter, isDisplaySub }) => {
  const router = useRouter();
  let detectDevice = UseMobileDetect();
  const matches = useMediaQuery("(min-width:768px)");
  const gps = cookies("gps")?.gps;
  const version = cookies("version")?.version || "";
  let lang = cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;
  let registerFB = cookies("registerFB")?.registerFB;
  let firstLogin = cookies("firstLogin")?.firstLogin;
  let showGuidelined = cookies("showGuidelined")?.showGuidelined;
  const [objAlert, setObjAlert] = useState({});
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [visible, setVisible] = useState(false);
  const [firsLoginVisible, setfirsLoginVisible] = useState(false);
  const [infoNoti, setInfoNoti] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "#fff",
    boxShadow: 0,
    px: 2,
    py: 2,
    borderRadius: "12px",
    outline: "none",
  };

  const HeartEmptyIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 31.764 28.716"
    >
      <g id="Group_280" data-name="Group 280" transform="translate(1 1)">
        <path
          id="Path_15"
          data-name="Path 15"
          d="M230.981,159.578a8.074,8.074,0,0,0-11.417,0l-1.1,1.1-1.1-1.1A8.074,8.074,0,1,0,205.952,171l1.1,1.1,11.422,11.422,11.417-11.422,1.1-1.1a8.07,8.07,0,0,0,0-11.421Z"
          transform="translate(-203.585 -157.214)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>,
    "heartEmpty"
  );

  const [auth, setAuth] = useState(authCookie || {});

  useEffect(() => {
    // kiem tra serviceWorker
    if (!registerFB && "serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration);
          document.cookie = `registerFB=true; path=/;`;
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }

    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
    };

    // co serviceWorker -> lang nghe tn den
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onReceivedMessage);
    }

    // chua co firebase -> khai bao firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    // user da dang nhap va co quyen nhan thong bao -> lay token firebase
    if (
      "Notification" in window &&
      Notification.permission === "granted" &&
      token?.length > 0
    ) {
      try {
        let messaging = firebase.messaging();

        if (messaging) {
          messaging
            .getToken()
            .then((token) => {
              console.log("token noti 1", token);
              signUpToken({ token: token, member_id: auth?.id })
                .then((req) => {})
                .catch((err) => {
                  console.log(err.response);
                });
            })
            .catch((err) => console.log("err1", err));
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    // user chua login, chua chon ngon ngu -> hien popup chon ngon ngu
    if ((!token || token?.length === 0) && !cookies("lang")?.lang) {
      setVisibleLanguage(true);
      isDisplayPopup = true;
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener(
          "message",
          onReceivedMessage
        );
      }
    } else {
      LanguageHook(router, cookies("lang")?.lang);
    }

    // goi api get thong bao moi tren chuong
    if (!globalCountNoti) {
      // nếu đang ở trang notification mà f5 thì gọi api read new noti
      if (router.pathname === "/notification") {
        readNotiCountNew({ member_id: authCookie?.id }).then((req) => {
          globalCountNoti = 0;
        });
      } else {
        // nếu không ở trang noti thì gọi api get số thông báo mới trên chuông
        getNotiCountNew({ member_id: authCookie?.id })
          .then((req) => {
            globalCountNoti = req?.data?.total;
          })
          .catch((err) => console.log("err get count noti"));
      }
    }

    const handleReceivedMessage = (event) => {
      if (event?.data) {
        let data = JSON.parse(event.data);
        // nhận thông tin version
        if (data?.version) {
          document.cookie = `version=${data.version}; path=/;`;
        }
        // clear gps khi user chặn quyền location
        if (data === "CLEAR_GPS") {
          document.cookie =
            "gps={}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
        // nhận thông tin user và token
        if (
          (!cookies("auth")?.auth || !cookies("token")?.token) &&
          data?.COOKIES
        ) {
          document.cookie = `token=${data.COOKIES?.token}`;
          document.cookie = `auth=${JSON.stringify(data.COOKIES?.auth)}`;
          document.cookie = `lang=${data.COOKIES?.lang}`;
          document.cookie = `showGuidelined=${data.COOKIES?.showGuidelined}`;
          document.cookie = `version=${data.COOKIES?.version}`;
          setAuth(data.COOKIES?.auth);
        }
      }
    };

    // start -> nhan thong tin version
    var iOS =
      !!navigator.platform &&
      /iPad|iPhone|iPod|MacIntel/.test(navigator.platform);
    var android =
      navigator.userAgent && navigator.userAgent.includes("Android");

    // document.cookie = "native=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT"

    if (iOS) {
      window.addEventListener("message", handleReceivedMessage);
    }
    if (android) {
      document.addEventListener("message", handleReceivedMessage);
    }
    return () => {
      if (iOS) {
        window.removeEventListener("message", handleReceivedMessage);
      }
      if (android) {
        document.removeEventListener("message", handleReceivedMessage);
      }
    };
    // end -> nhan
  }, []);

  useEffect(() => {
    if (
      cookies("lang")?.lang &&
      (((!token || token?.length === 0) && !showGuidelined) || firstLogin)
    ) {
      setfirsLoginVisible(true);
      document.cookie = "showGuidelined=true; path=/;";
      document.cookie =
        "firstLogin=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }, [cookies("lang")?.lang]);

  useEffect(() => {
    if (router?.query?.update) {
      setAuth(cookies("auth")?.auth);
    }
  }, [router.query]);

  useEffect(() => {
    // if (detectDevice.isMobile()) {
    if (version?.length > 0) {
      sendMessUserInfo();
    }
  }, []);

  function getLocation() {
    // nếu chưa có gps thì xin cấp quyền lấy location, nếu quyền bị block thì xóa gps đã lưu (nếu có)
    if (navigator.geolocation) {
      if (!gps || (gps && Object.keys(gps)?.length === 0)) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        if (navigator.permissions) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
              if (result.state === "denied") {
                document.cookie =
                  "gps={}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
              }
            });
        }
      }
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    let location = {
      x: position?.coords?.latitude,
      y: position?.coords?.longitude,
    };
    document.cookie = `gps=${JSON.stringify(
      location
    )}; path=/; expires=${moment().add(1, "day").toString()}`;
  }

  const onReceivedMessage = (message) => {
    let payload = (message?.data && Object.values(message.data)?.[1]) || {};

    globalCountNoti = globalCountNoti + 1;
    setInfoNoti(payload?.data);
    setObjAlert({
      type: "info",
      text: payload?.data?.[lang]?.title || payload?.data?.title,
      subText: payload?.data?.[lang]?.body || payload?.data?.body,
    });
  };

  const sendMessOpenNoti = () => {
    if (token?.length > 0) {
      if (window.ReactNativeWebView && version?.length > 0) {
        window.ReactNativeWebView.postMessage("OPEN_NOTIFY");
      } else {
        goToPage("/notification", {});
      }

      readNotiCountNew({ member_id: authCookie?.id }).then((req) => {
        globalCountNoti = 0;
      });
    } else {
      setVisible(true);
    }
  };

  const sendMessUserInfo = () => {
    if (window.ReactNativeWebView) {
      // window.ReactNativeWebView.postMessage("USER_INFO")
      let data = {
        auth: auth,
        token: token,
        gps: gps,
        lang: lang,
      };
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ USER_INFO: data })
      );
    }
  };

  const goToPage = (url, _filter) => {
    if (_filter?.slug) delete _filter.slug;
    router.push(
      {
        pathname: url,
        query: _filter && renderURL("", _filter),
      },
      undefined,
      { shallow: true }
    );
  };

  const checkPostTopic = () => {
    // if (auth?.type === TYPE_ORGANIZATION?.id || auth?.type === TYPE_HELPER?.id) {
    //     if (auth?.memberVerify?.status === STATUS_ACCEPTED?.id) {
    //         goToPage('/user/post')
    //     } else {
    //         setObjAlert({
    //             type: "warning",
    //             text: "Bạn cần xác minh để đăng tin!",
    //         })
    //     }
    // } else {
    //     goToPage('/user/post')
    // }
    goToPage("/user/post");
  };

  const renderMenu = () => {
    return (
      <>
        <Stack
          direction={matches ? "row" : "column"}
          justifyContent="center"
          alignItems={matches ? "flex-end" : "center"}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            goToPage("/", {
              memberTypes: [TYPE_HELPER?.id, TYPE_ORGANIZATION?.id],
              types: `[${CATE_PUBLIC?.id}]`,
              categoryId: TYPE_NEWS?.id,
            })
          }
        >
          <HomeIcon
            sx={{ width: "30px", height: "20px" }}
            style={
              router.pathname === "/"
                ? { stroke: "#000", strokeWidth: "1" }
                : { color: "#fff" }
            }
          />
          <p
            style={
              router.pathname === "/"
                ? {
                    color: "#000",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                  }
                : {
                    color: "#fff",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                  }
            }
          >
            {Langs?.trang_chu?.[lang]}
          </p>
        </Stack>

        <Stack
          direction={matches ? "row" : "column"}
          justifyContent="center"
          alignItems={matches ? "flex-end" : "center"}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            goToPage("/community", {
              types: [TYPE_HELPER?.id, TYPE_ORGANIZATION?.id],
              isAvailable: undefined,
            })
          }
        >
          <CommunityIcon
            sx={{ width: "30px", height: "20px" }}
            style={
              router.pathname === "/community"
                ? { stroke: "#000", strokeWidth: "1" }
                : { color: "#fff" }
            }
          />
          <p
            style={
              router.pathname === "/community"
                ? {
                    color: "#000",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                  }
                : {
                    color: "#fff",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                  }
            }
          >
            {Langs?.cong_dong?.[lang]}
          </p>
        </Stack>

        <Stack
          direction={matches ? "row" : "column"}
          justifyContent="center"
          alignItems={matches ? "flex-end" : "center"}
          sx={{ cursor: "pointer" }}
          onClick={() =>
            token?.length > 0 ? checkPostTopic() : setVisible(true)
          }
        >
          <PostIcon
            sx={{ width: "30px", height: "20px" }}
            style={
              router.pathname === "/user/post"
                ? { stroke: "#000", strokeWidth: "1" }
                : { color: "#fff" }
            }
          />
          <p
            style={
              router.pathname === "/user/post"
                ? {
                    color: "#000",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                  }
                : {
                    color: "#fff",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                  }
            }
          >
            {Langs?.dang_tin?.[lang]}
          </p>
        </Stack>

        <Stack
          direction={matches ? "row" : "column"}
          justifyContent="center"
          alignItems={matches ? "flex-end" : "center"}
          sx={{ cursor: "pointer" }}
          onClick={sendMessOpenNoti}
        >
          <Badge
            // badgeContent={globalCountNoti}
            badgeContent={0}
            max={9}
            variant="standard"
            color="borderWhite"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            overlap="circular"
          >
            <NotiIcon
              sx={{ width: "30px", height: "20px" }}
              style={
                router.pathname === "/notification"
                  ? { stroke: "#000", strokeWidth: "1" }
                  : { stroke: "#fff" }
              }
            />
          </Badge>
          <p
            style={
              router.pathname === "/notification"
                ? {
                    color: "#000",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                    marginLeft: "5px",
                  }
                : {
                    color: "#fff",
                    fontSize: "10px",
                    marginBottom: 0,
                    marginTop: 0,
                    marginLeft: "5px",
                  }
            }
          >
            {Langs?.thong_bao?.[lang]}
          </p>
        </Stack>
      </>
    );
  };

  const onClickDetail = (item) => {
    if (infoNoti?.categoryType * 1 === 5 || infoNoti?.categoryType * 1 === 7) {
      //5 === member: bao cao vi pham tai khoan, 7 === follower: rating tha tim
      router.push({
        pathname: `/user/${infoNoti?.id}`,
      });
    } else {
      router.push({
        pathname: `/notification/${infoNoti?.notifyId}`,
        query: {
          type: infoNoti?.categoryType,
          value: infoNoti?.type * 1 === 1 ? infoNoti?.notifyId : infoNoti?.id,
          isExpired:
            infoNoti?.categoryType * 1 === 3
              ? infoNoti?.isExpired || -1
              : undefined,
        },
      });
    }

    readOneNoti(item?.notifyItemId, { member_id: authCookie?.id });
  };

  const renderCarouselItem = () => {
    return slides.map((item) => {
      return (
        <Box key={item?.id} sx={{ paddingBottom: "50px", height: "270px" }}>
          <svg width={45} height={45} color="#f47376">
            {item?.icon}
          </svg>
          <Typography
            variant="h6"
            mb={1}
            sx={{ color: "#f47376", fontWeight: "bold" }}
          >
            {item?.title?.[lang]}
          </Typography>
          <Typography variant="body2" mb={2}>
            {item?.content?.[lang]}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "#5cb4e9", fontWeight: "bold" }}
          >
            {item?.extra?.[lang]}
          </Typography>
        </Box>
      );
    });
  };

  const updateCurrentSlide = (index) => {
    setCurrentSlide(index);
  };

  const closeGuidelineModal = () => {
    document.cookie =
      "firstLogin=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    setfirsLoginVisible(false);
  };

  return (
    <Box className="div-header">
      <Head>
        <title> Kết nối yêu thương </title>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      </Head>
      {objAlert && Object.keys(objAlert)?.length > 0 && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={objAlert && Object.keys(objAlert)?.length > 0}
          autoHideDuration={6000}
          onClose={() => setObjAlert({})}
        >
          {objAlert?.subText ? (
            <Alert
              severity={objAlert?.type}
              sx={{ width: "100%" }}
              onClick={onClickDetail}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {objAlert?.text}
              </Typography>
              <Typography variant="body2">{objAlert?.subText}</Typography>
            </Alert>
          ) : (
            <Alert severity={objAlert?.type} sx={{ width: "100%" }}>
              {objAlert?.text}
            </Alert>
          )}
        </Snackbar>
      )}

      <div className="div-header-default-layout">
        <NoSsr>
          <Container maxWidth="md" sx={{ padding: 0 }}>
            <Box
              justifyContent="space-between"
              sx={{ paddingBottom: matches ? "0" : "10px" }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={matches ? 3 : 1}
                alignItems="center"
              >
                {auth && Object.keys(auth).length > 0 ? (
                  <Stack direction="row" justifyContent="flex-start">
                    <Avatar
                      onClick={() =>
                        auth && Object.keys(auth).length > 0
                          ? goToPage(`/user/${auth.id}`, { update: true })
                          : {}
                      }
                      sx={{ width: 34, height: 34 }}
                      alt="Avatar"
                      src={
                        auth &&
                        Object.keys(auth)?.length > 0 &&
                        auth?.images?.avatar
                          ? `${process.env.NEXT_PUBLIC_MEDIA_API}${auth?.images?.avatar}`
                          : defaultImg
                      }
                    />
                    {matches && (
                      <Stack direction="column" sx={{ marginLeft: "20px" }}>
                        <Typography
                          variant="body2"
                          className="title-header pointer"
                          sx={{ marginLeft: "0 !important" }}
                          onClick={() =>
                            goToPage(`/user/${auth.id}`, { update: true })
                          }
                        >
                          {decodeURIComponent(auth?.name) || ""}
                        </Typography>
                        <Typography variant="body2" sx={{ height: "18px" }}>
                          <HeartEmptyIcon
                            style={{ fontSize: "18px", marginRight: "5px" }}
                          />
                          <span
                            style={{ verticalAlign: "super", color: "#fff" }}
                          >
                            {auth?.totalBeingFollowed
                              ? numeral(auth?.totalBeingFollowed).format("0,0")
                              : 0}
                          </span>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                ) : (
                  <Button
                    onClick={() =>
                      router.push({
                        pathname: "/user/login",
                        // query: router?.query
                      })
                    }
                    sx={{ fontSize: "10px" }}
                    className="btn-header-register "
                    size="small"
                    variant="contained"
                  >
                    {Langs?.dang_nhap?.[lang]}
                  </Button>
                )}
                {matches ? (
                  <Stack
                    direction="row"
                    justifyContent={"flex-end"}
                    spacing={3}
                  >
                    {renderMenu()}
                  </Stack>
                ) : (
                  <>{renderMenu()}</>
                )}
              </Stack>
            </Box>
          </Container>
        </NoSsr>
      </div>
      {isDisplaySub && (
        <div className="div-sub-menu">
          <NoSsr>
            <Container maxWidth="md" sx={{ padding: 0 }}>
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent="space-between"
                spacing={0}
              >
                <div
                  className="text-center pointer div-btn-sub-menu"
                  onClick={() =>
                    goToPage("/gift", {
                      isAvailable: AVAILABLE_ACTIVE?.id,
                      categoryId: TYPE_GIFT?.id,
                    })
                  }
                  style={
                    router.pathname !== "/gift" ? { boxShadow: "none" } : {}
                  }
                >
                  <GiftIcon sx={{ height: 18, width: 18 }} />
                  <p
                    style={{
                      fontSize: "10px",
                      marginBottom: 0,
                      marginTop: "-5px",
                    }}
                  >
                    {Langs?.cho_tang?.[lang]}
                  </p>
                </div>

                <div
                  className="text-center pointer div-btn-sub-menu"
                  onClick={() =>
                    goToPage("/move", {
                      isAvailable: AVAILABLE_ACTIVE?.id,
                      categoryId: TYPE_MOVE?.id,
                    })
                  }
                  style={
                    router.pathname !== "/move" ? { boxShadow: "none" } : {}
                  }
                >
                  <CarIcon
                    sx={{
                      height: 18,
                      width: 18,
                      transform: "scale(1.3)",
                      marginTop: "0px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "10px",
                      marginBottom: 0,
                      marginTop: "-5px",
                    }}
                  >
                    {Langs?.van_chuyen?.[lang]}
                  </p>
                </div>

                <div
                  className="text-center pointer div-btn-sub-menu"
                  onClick={() =>
                    goToPage("/job", {
                      isAvailable: AVAILABLE_ACTIVE?.id,
                      categoryId: TYPE_JOB?.id,
                    })
                  }
                  style={
                    router.pathname !== "/job" ? { boxShadow: "none" } : {}
                  }
                >
                  <JobIcon sx={{ height: 18, width: 18 }} />
                  <p
                    style={{
                      fontSize: "10px",
                      marginBottom: 0,
                      marginTop: "-5px",
                    }}
                  >
                    {Langs?.cong_viec?.[lang]}
                  </p>
                </div>

                <div
                  className="text-center pointer div-btn-sub-menu"
                  onClick={() =>
                    goToPage("/place", {
                      isAvailable: AVAILABLE_ACTIVE?.id,
                      categoryId: TYPE_PLACE?.id,
                    })
                  }
                  style={
                    router.pathname !== "/place" ? { boxShadow: "none" } : {}
                  }
                >
                  <HomeColorIcon sx={{ height: 18, width: 18 }} />
                  <p
                    style={{
                      fontSize: "10px",
                      marginBottom: 0,
                      marginTop: "-5px",
                    }}
                  >
                    {Langs?.cho_o?.[lang]}
                  </p>
                </div>

                <div
                  className="text-center pointer div-btn-sub-menu"
                  onClick={() =>
                    goToPage("/market", {
                      isAvailable: AVAILABLE_ACTIVE?.id,
                      categoryId: TYPE_MARKET?.id,
                    })
                  }
                  style={
                    router.pathname !== "/market" ? { boxShadow: "none" } : {}
                  }
                >
                  <MarketIcon sx={{ height: 18, width: 18 }} />
                  <p
                    style={{
                      fontSize: "10px",
                      marginBottom: 0,
                      marginTop: "-5px",
                    }}
                  >
                    {Langs?.cho_yeu_thuong?.[lang]}
                  </p>
                </div>
              </Stack>
            </Container>
          </NoSsr>
        </div>
      )}

      {visibleLanguage && (
        <Modal
          open={visibleLanguage}
          onClose={() => setVisibleLanguage(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: "99" }}
        >
          <Box sx={{ ...style }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Chọn ngôn ngữ - Select language
            </Typography>
            <Divider sx={{ margin: "10px -20px 0 -20px" }} />
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    LanguageHook(router, "vi");
                    setVisibleLanguage(false);
                  }}
                >
                  <img
                    src={"/vietnam.png"}
                    style={{
                      width: "25px",
                      height: "18px",
                      objectFit: "cover",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Tiếng Việt - Vietnamese
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    LanguageHook(router, "en");
                    setVisibleLanguage(false);
                  }}
                >
                  <img
                    src={"/united-kingdom.png"}
                    style={{
                      width: "25px",
                      height: "18px",
                      objectFit: "cover",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Tiếng Anh - English
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Modal>
      )}

      <Modal
        open={firsLoginVisible}
        onClose={() => setfirsLoginVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Carousel
            showIndicators={true}
            swipeable={true}
            emulateTouch={true}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            dynamicHeight={true}
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
          >
            {renderCarouselItem()}
          </Carousel>

          <Stack direction="row" justifyContent="space-between">
            <Stack
              direction="row"
              justifyItems="center"
              onClick={() => setCurrentSlide(currentSlide - 1)}
              sx={
                currentSlide === 0
                  ? { visibility: "hidden", cursor: "pointer" }
                  : { visibility: "visible", cursor: "pointer" }
              }
            >
              <KeyboardArrowLeftIcon sx={{ paddingBottom: "5px" }} />
              <Typography variant="caption">{Langs?.tro_ve?.[lang]}</Typography>
            </Stack>

            {currentSlide === slides?.length - 1 ? (
              <Stack
                direction="row"
                justifyItems="center"
                onClick={closeGuidelineModal}
                sx={{ cursor: "pointer" }}
              >
                <Typography variant="caption">
                  {Langs?.hoan_tat?.[lang]}
                </Typography>
              </Stack>
            ) : (
              <Stack
                direction="row"
                justifyItems="center"
                onClick={() => setCurrentSlide(currentSlide + 1)}
                sx={
                  currentSlide === slides?.length - 1
                    ? { visibility: "hidden", cursor: "pointer" }
                    : { visibility: "visible", cursor: "pointer" }
                }
              >
                <Typography variant="caption">
                  {Langs?.tiep_theo?.[lang]}
                </Typography>
                <KeyboardArrowRightIcon sx={{ paddingBottom: "5px" }} />
              </Stack>
            )}
          </Stack>
        </Box>
      </Modal>

      <ModalLoginAlert
        visible={visible}
        setVisible={(vis) => setVisible(vis)}
        lang={lang}
      />
    </Box>
  );
};

// export const getServerSideProps = async ({ query, params, ...ctx }) => {
//     const authCookie = cookies(ctx)?.auth ?? {}
//     const token = cookies(ctx)?.token ?? ""

//     console.log('server header', token, authCookie)
//     return {
//         props: {
//             authCookie: authCookie,
//             token: token
//         },
//     };
// };

export default Header;
