import GetWidthScreen from "components/Hook/GetWidthScreen";
import _ from "lodash";

export const renderURL = (url, params, formatter) => {
  let paramsValue = _.compact(
    _.map(params, (p, key) => {
      if (p) {
        if (typeof p === "object") {
          if (p.id) return `${key}=${p.id}`;
          else if (_.isArray(p)) {
            return `${key}=[${p}]`;
          } else {
            let formatDate = moment(p).format(
              formatter ? formatter : "DD-MM-YYYY"
            );
            return `${key}=${formatDate}`;
          }
        } else {
          return `${key}=${p}`;
        }
      }
    })
  );
  return _.map(paramsValue, (pv, index) => (!index ? pv : "&" + pv)).join("");
};
