import React, { useEffect } from "react";
import Image from "next/image";
import styles from "./Article.module.scss";
import { Box } from "@mui/system";
import { Paper, useMediaQuery } from "@mui/material";

const ArticleBanner = ({
  banner,
  srcset,
  bannerAlt,
  width,
  height,
  isNotRadiusBorder,
  ...rest
}) => {
  const matches = useMediaQuery("(min-width:768px)");
  const msScreen = useMediaQuery("(max-width:1000px)");

  return (
    // <Paper sx={{ backgroundColor: "transparent", borderRadius: isNotRadiusBorder ? 0 : "12px", height: height ?? 225 + "!important" }} elevation={rest?.elevation ?? 1} {...rest}>
    <Box>
      {!banner?.includes("http") ? (
        <img
          className={!isNotRadiusBorder && styles.imgWrapper}
          src={banner}
          alt={bannerAlt}
          width={"100%"}
          height={height ?? 255}
          style={{ objectFit: "cover" }}
          srcSet={srcset || undefined}
        />
      ) : (
        <Image
          className={!isNotRadiusBorder && styles.imgWrapper}
          src={banner || ""}
          alt={bannerAlt}
          srcSet={srcset || undefined}
          width={height && matches ? (msScreen ? 770 : 860) : 375}
          height={height ?? 255}
          layout={height && matches ? "fixed" : "responsive"}
          quantity={100}
          objectFit="cover"
          placeholder="blur"
          blurDataURL={banner}
        />
      )}
    </Box>
  );
};

export default ArticleBanner;
