import CarIcon from "./Icons/CarIcon";
import CommunityIcon from "./Icons/CommunityIcon";
import GiftIcon from "./Icons/GiftIcon";
import HomeColorIcon from "./Icons/HomeColorIcon";
import HomeIcon from "./Icons/HomeIcon";
import JobIcon from "./Icons/JobIcon";
import MarketIcon from "./Icons/MarketIcon";
import PostIcon from "./Icons/PostIcon";

const SLIDE1 = {
  id: 1,
  title: {
    vi: "Trang chủ",
    en: "Homepage",
  },
  content: {
    vi: "Nơi cập nhật các tin tức và hoạt động từ thiện của Tổ chức & Mạnh thường quân (MTQ). Bạn có thể nhờ giúp đỡ hoặc đăng ký làm tình nguyện viên tại đây",
    en: "The place to update news and charity activities of Organization & Sponsorship. You can ask for help or register as a volunteer here ",
  },
  extra: {
    vi: "Chỉ Tổ chức & MTQ được đăng tin tại Trang chủ",
    en: "Only Organizations & Sponsorship are allowed to post on the Homepage",
  },
  icon: <HomeIcon />,
};
const SLIDE2 = {
  id: 2,
  title: {
    vi: "Cho tặng",
    en: "Donate",
  },
  content: {
    vi: "Bạn có thể cho những gì bạn có, và lấy những gì  bạn cần. Giúp đỡ lẫn nhau trong lúc khó khăn nhé.  ",
    en: "You can give what you have, and take what you need. Let's help each other in difficult times.",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <GiftIcon />,
};
const SLIDE3 = {
  id: 3,
  title: {
    vi: "Vận chuyển",
    en: "Transport",
  },
  content: {
    vi: "Ở đây, bạn có thể tìm kiếm các dịch vụ vận chuyển,  xe ôm,... hoặc đăng thông tin dịch vụ vận chuyển  của mình.  ",
    en: "Here, you can search for transportation services or post information about your transportation services.",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <CarIcon />,
};
const SLIDE4 = {
  id: 4,
  title: {
    vi: "Công việc",
    en: "Job",
  },
  content: {
    vi: "Giúp đỡ những người bị thất nghiệp hoặc tìm kiếm một công việc phù hợp với bản thân ở đây nhé.  ",
    en: "Help people who are unemployed or find a job that suits you here.    ",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <JobIcon />,
};
const SLIDE5 = {
  id: 5,
  title: {
    vi: "Chỗ ở",
    en: "Accommodation",
  },
  content: {
    vi: "Nơi các mạnh thường quân giúp đỡ những người  khó khăn về nơi ở. Nếu bạn cũng là người có thể  giúp đỡ, hãy đăng bài ở đây nhé.  ",
    en: "Where sponsors help those in need. If you are someone who can help, please post here.",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <HomeColorIcon />,
};
const SLIDE6 = {
  id: 6,
  title: {
    vi: "Chợ yêu thương",
    en: "Cherish market",
  },
  content: {
    vi: "Không chỉ có những người gặp hoàn cảnh khó khăn mới cần đến thực phẩm cho tặng, kể cả  mạnh thường quân hoặc tổ chức từ thiện cũng có thể tìm nhà cung cấp với giá cả hợp lí tại đây.   Giúp đỡ lẫn nhau, tại sao không?",
    en: "Not only people in difficult circumstances need food, even donors or charity organizations can find suppliers with reasonable prices here. Help each other, why not?",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <MarketIcon />,
};
const SLIDE7 = {
  id: 7,
  title: {
    vi: "Cộng đồng",
    en: "Community",
  },
  content: {
    vi: "Tổ chức & Mạnh thường quân đang hoạt động  trong cộng đồng của tụi mình nè!  ",
    en: "Active Organizations & Sponsors in our community!",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <CommunityIcon />,
};
const SLIDE8 = {
  id: 8,
  title: {
    vi: "Đăng tin",
    en: "Post",
  },
  content: {
    vi: "Bạn đã chuẩn bị đăng tải các thông tin hữu ích để giúp đỡ các mảnh đời khó khăn chưa ? Hoặc nếu bạn đang gặp khó khăn, đừng ngại hãy chia sẻ cho cộng đồng cùng chung tay giúp đỡ nhé",
    en: "Have you prepared to post useful information to help those in need? Or if you are having difficulties, don't be afraid to share it with the community to help .    ",
  },
  extra: {
    vi: "",
    en: "",
  },
  icon: <PostIcon />,
};

const slides = [SLIDE1, SLIDE2, SLIDE3, SLIDE4, SLIDE5, SLIDE6, SLIDE7, SLIDE8];

export {
  SLIDE1,
  SLIDE2,
  SLIDE3,
  SLIDE4,
  SLIDE5,
  SLIDE6,
  SLIDE7,
  SLIDE8,
  slides,
};
