const TYPE_PERSONAL = {
    id: 1,
    vi: "Cá nhân",
    en: "Individual",
    color: "#76DD78"
}
const TYPE_PARTY = {
    id: 2,
    vi: "Tổ chức",
    en: "Organization",
    color: "#89D0F2"
}
const TYPE_HELPER = {
    id: 3,
    vi: "Mạnh thường quân",
    en: "Sponsor",
    color: "#FFB84F"
}

const types = [TYPE_PERSONAL, TYPE_PARTY, TYPE_HELPER]

const findType = (typeID, field) => {
    const finded = types.find(type => type.id === typeID * 1)
    if (!finded) return undefined
    if (!field) return finded
    return finded[field]
}

export {
    TYPE_PERSONAL,
    TYPE_PARTY,
    TYPE_HELPER,
    types,
    findType
}