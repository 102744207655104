const { createSvgIcon } = require("@mui/material");

const HomeIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 26.743">
        <g id="home" transform="translate(0.001 -27.797)">
            <g id="Group_33" data-name="Group 33" transform="translate(-0.001 27.798)">
                <g id="Group_32" data-name="Group 32">
                    <path id="Path_20" data-name="Path 20" d="M29.68,40.164,15.46,27.974a.689.689,0,0,0-.921,0L.318,40.164A1.018,1.018,0,0,0,.15,41.439a.709.709,0,0,0,1.089.2L15,29.841l13.76,11.8a.7.7,0,0,0,.46.176.748.748,0,0,0,.63-.374A1.017,1.017,0,0,0,29.68,40.164Z" transform="translate(0.001 -27.798)" />
                </g>
            </g>
            <g id="Group_35" data-name="Group 35" transform="translate(3.872 37.276)">
                <g id="Group_34" data-name="Group 34">
                    <path id="Path_21" data-name="Path 21" d="M77.965,232.543a.841.841,0,0,0-.742.913v14.526H71.289v-7.929c0-2.516-1.664-4.563-3.709-4.563s-3.709,2.047-3.709,4.563v7.929H57.936V233.456a.758.758,0,1,0-1.484,0v15.439a.841.841,0,0,0,.742.913h7.418a.825.825,0,0,0,.739-.841c0-.021,0-.045,0-.071v-8.842a2.274,2.274,0,1,1,4.451,0v8.842c0,.026,0,.049,0,.07a.825.825,0,0,0,.739.842h7.418a.841.841,0,0,0,.742-.913V233.456A.841.841,0,0,0,77.965,232.543Z" transform="translate(-56.452 -232.543)" />
                </g>
            </g>
        </g>
    </svg>
    , 'Home')

export default HomeIcon