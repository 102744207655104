// Next.js API route support: https://nextjs.org/docs/api-routes/introduction

import axiosClient from "../api/axiosClient";
import cookies from "next-cookies";
import _ from "lodash";

const getToken = () => {
  return cookies("token")?.token ?? "";
};

const login = (data) => {
  return axiosClient.post("sign-in", data);
};

const logout = (tk) => {
  return axiosClient.post(
    "sign-out",
    {},
    {
      headers: {
        Authorization: "Bearer " + tk,
      },
    }
  );
};

const register = (data) => {
  return axiosClient.post("sign-up", data);
};

const getUserInfo = (id) => {
  return axiosClient.get("/members/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getLocation = (params) => {
  return axiosClient.get("/locations", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putUser = (data) => {
  return axiosClient.put("members", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPasswordUser = (data) => {
  return axiosClient.put("members-password", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postTopic = (data) => {
  return axiosClient.post("posts", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getTopicInfo = (id) => {
  return axiosClient.get("/posts/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putTopic = (data) => {
  return axiosClient.put(`posts/${data.id}`, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putStatusTopic = (data) => {
  return axiosClient.put(`posts-status/${data.id}`, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postVerifyUser = (data) => {
  return axiosClient.post("member-verify-infos", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getVerifyUserInfo = (id) => {
  return axiosClient.get("/member-verify-infos/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putVerifyUser = (data) => {
  return axiosClient.put(`member-verify-infos`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postSupport = (data) => {
  return axiosClient.post("requests", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postHeartUser = (data) => {
  return axiosClient.post("followers", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putHeartUser = (data) => {
  return axiosClient.put(`followers`, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postOtp = (data) => {
  return axiosClient.post("send-otp", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const confirmOtp = (data) => {
  return axiosClient.post("comfirm-otp", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const changePasswordUserOneTimeToken = (data) => {
  return axiosClient.post(
    "change-password-by-one-time-token",
    { password: data.password },
    {
      headers: {
        Authorization: "Bearer " + data.token,
      },
    }
  );
};

const getFollowers = (params) => {
  return axiosClient.get(`/followers`, {
    params: _.pickBy(params, _.identify),
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  login,
  logout,
  register,
  getUserInfo,
  getLocation,
  putUser,
  putPasswordUser,
  postTopic,
  getTopicInfo,
  putTopic,
  putStatusTopic,
  postVerifyUser,
  getVerifyUserInfo,
  putVerifyUser,
  postSupport,
  postHeartUser,
  putHeartUser,
  postOtp,
  confirmOtp,
  changePasswordUserOneTimeToken,
  getFollowers,
};
