import { Stack, Typography, Button } from '@mui/material';
import React from 'react';
import moment from 'moment-mini'
import CustomButton from 'components/CustomButton';

const ArticleFooter = ({ date, format = "HH:mm - DD/MM/YYYY", area, children }) => {
    return (
        <Stack
            className="article-owner"
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            mt={1}
        >
            {children
                ? children
                : <Typography variant="body2" sx={{ textTransform: "capitalize" }}>{date ? moment(date).format(format) : undefined} - {area?.replace("Tỉnh", "")?.replace("Thành phố", "")}</Typography>
            }
            {/* <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ borderRadius: 50, py: "2px", px: "10px" }}
            >
                Xem
            </Button> */}
            {/* <CustomButton
                bgColor="red"
                size="small"
                sx={{ backgroundColor: "#FE9292", borderRadius: 50, py: "2px", px: "10px" }}
                variant="contained"
            >Xem</CustomButton> */}
        </Stack>
    );
};

export default ArticleFooter;