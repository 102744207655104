import axios from "axios";

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API + process.env.NEXT_PUBLIC_API_VERSION,
});

// instance.interceptors.request.use(function (config) {
//     return config;
// }, function (error) {
//     return Promise.reject(error)
// })

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      document.cookie =
        "auth={}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
      document.cookie = "token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
      alert("Tài khoản của bạn đã hết hiệu lực. Vui lòng đăng nhập lại.");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("USER_LOGOUT");
      }
      window.location.replace("/user/login");
    }
    return Promise.reject(error);
  }
);

export default instance;
